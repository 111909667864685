import React from "react";
import SearchForm from "../components/Vehicles";

const Vehicles = () => {
  return (
    <div className="Container">
      <SearchForm />
    </div>
  );
};
export default Vehicles;
