import React from "react";
import TabsBasic from "../components/Service/Service_main";

const Services = () => {
  return (
    <div className="Container">
      <TabsBasic />
    </div>
  );
};
export default Services;
